import React, { useContext, useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { SharedReportPageProps } from './SharedPageProps';
import { useQueryParam } from '../../hooks';
import { StringUtil, NotificationUtil, TableColumnBuilder, NameOf } from '../../utils';
import ConfigurationController from '../../api/ConfigurationController';
import AdvancedSettingsDTO from '../../models/generated/AdvancedSettingsDTO';
import { ColumnProps } from 'antd/lib/table';

const PrintingReportAdvancedSettings: React.FC<SharedReportPageProps> = (props) => {
    const authContext = useContext(AuthenticationContext);
    const [mappingItemIdQueryParam, setMappingItemIdQueryParam] = useQueryParam('mappingItemId');
    const [tableData, setTableData] = useState<AdvancedSettingsDTO[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadTableData();
    }, []);

    const loadTableData = async () => {
        if (authContext.account == null || authContext.location == null) {
            return;
        }

        setLoading(true);
        try {
            const results = await ConfigurationController.getAdvancedSettings(authContext.account!.id, authContext.location!.id);
            const sortedData = [...results.data].sort((a, b) => a.displayOrder - b.displayOrder).filter(x => {
                return StringUtil.IsNullOrEmpty(mappingItemIdQueryParam) ? true : x.key.toLowerCase() === mappingItemIdQueryParam.toLowerCase();                
            });
            
            setTableData(sortedData);
        } catch (error) {
            NotificationUtil.error({
                key: 'PrintingReportAdvancedSettings',
                message: 'Error while loading Report data',
                error
            });
        }
        setLoading(false);
    };
    const tableColumns: ColumnProps<AdvancedSettingsDTO>[] = [
        TableColumnBuilder.Create<AdvancedSettingsDTO>('displayName', 'Name')
            .AddRenderer('Text')
            .VerticalAlign('top')
            .Build(),
        TableColumnBuilder.Create<AdvancedSettingsDTO>('settingValue', 'Value')
            .AddRenderer('Text')
            .Build()
    ];
    return (
        <div className='printing-report-advanced-settings'>
            <Skeleton active loading={loading}>
                <Table
                    rowKey={NameOf<AdvancedSettingsDTO>('key')}
                    bordered
                    className='condensed-table'
                    loading={loading}
                    pagination={false}
                    columns={tableColumns}
                    dataSource={tableData}
                >
                </Table>
            </Skeleton>
        </div>
    );
};

export default PrintingReportAdvancedSettings;