import React, { useContext, useEffect, useState } from 'react';
import { Button, Popover, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { NameOf, NotificationUtil, TableColumnBuilder } from 'src/utils';
import { useMultiState } from 'src/hooks';
import ConfigurationController from 'src/api/ConfigurationController';
import MultiToolPicker from 'src/components/MultiToolPicker';
import SimplePopover from 'src/components/SimplePopover';
import AdvancedSettingsDTO from 'src/models/generated/AdvancedSettingsDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import LinkWithQuery from '../../components/LinkWithQuery';
import PrintIconSVG from '../../components/svgs/PrintIconSVG';
import RouteConfig from '../../config/RouteConfig';

const LocationSettingsAdvancedSettings: React.FC = () => {
    const authContext = useContext(AuthenticationContext);
    const [tableData, setTableData] = useState<AdvancedSettingsDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const [getMultiSubmitting, setMultiSubmitting] = useMultiState<boolean>();

    // OnLoad
    useEffect(() => {
        loadTableData();
    }, []);
    // Dev Note: In an effort to have these print buttons semi-standard, I am adding the record and doing work here
    const renderPrintButton = (record?: AdvancedSettingsDTO) => {
        // This will give us a return URL, without the route_id
        const url = new URL(location.href);
        url.searchParams.delete('route_id');
        const printingQueryParams: [string, string][] = [
            ['mappingItemId', record?.key ?? ''],
            ['returnUrl', url.pathname + url.search],
        ];

        return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_ADVANCED_SETTINGS()} additionalQueryParams={printingQueryParams}>
            <Button type='link' icon={<PrintIconSVG />} />
        </LinkWithQuery>;
    };
    const loadTableData = async () => {
        setLoading(true);
        try {
            const results = await ConfigurationController.getAdvancedSettings(authContext.account!.id, authContext.location!.id);

            // Sort on ID so everything is roughly in the same order
            const sortedData = [...results.data].sort((a, b) => a.displayOrder - b.displayOrder);

            setTableData(sortedData);
        } catch (error) {
            NotificationUtil.error({
                key: 'LocationSettingsAdvancedSettings',
                message: 'Error while loading Integrations',
                error
            });
        }
        setLoading(false);
    };

    const handleSettingValueFinish = async (record: AdvancedSettingsDTO, value: string) => {
        setMultiSubmitting(true, record.key);

        try {
            // Submit to API
            let settings: AdvancedSettingsDTO = { ...record, settingValue: value, settingValue_IsUpdate: true };
            await ConfigurationController.saveAdvancedSetting(authContext.account!.id, authContext.location!.id, settings);

            // Update the records
            setTableData(prev => {
                const found = prev.find(x => x.key === record.key);
                if (found != null) {
                    found.settingValue = value;
                }
                return prev;
            });

            NotificationUtil.close('LocationSettingsAdvancedSettings');
        } catch (error) {
            NotificationUtil.error({
                key: 'LocationSettingsAdvancedSettings',
                message: 'Advanced Settings',
                description: 'Error while saving settings',
                error
            });
        }
        setMultiSubmitting(false, record.key);
    };

    const tableColumns: ColumnProps<AdvancedSettingsDTO>[] = [
        TableColumnBuilder.Create<AdvancedSettingsDTO>('displayName', 'Name')
            .AddSorter('Text')
            .AddTextFilterer()
            .AddRenderer('Custom', (value, record) => {
                return <>
                    {value}
                    {record.displayInfo && <SimplePopover
                        dangerouslySetHTML
                        content={record.displayInfo}
                    />}
                </>;
            })
            .Build(),
        TableColumnBuilder.Create<AdvancedSettingsDTO>('settingValue', 'Value')
            .AddRenderer('Custom', (_, record) => {
                return <MultiToolPicker
                    enableSaveBeforeFinish
                    style={{ width: 400 }}
                    enableCarefulEntry={record.settingValueDisplayType !== 'Label'}
                    warningMessage={record.displayWarning}
                    defaultValue={record.settingValue}
                    formatType={record.settingValueFormatType}
                    displayType={record.settingValueDisplayType}
                    options={record.settingValueOptionCollection}
                    loading={getMultiSubmitting(record.key)}
                    onFinish={value => handleSettingValueFinish(record, value)}
                />;
            }).Build(),
        TableColumnBuilder.CreateCustom<AdvancedSettingsDTO>('key','')
            .AddRenderer('Custom', (_, record) => {
                return <>
                    {/*{renderPrintButton(record)}*/}
                </>;
            })
            .Title(<>{renderPrintButton()}</>)
            .Build(),
    ];

    return (
        <div className='location-settings-advanced-settings'>
            <p style={{ width: 800 }}>Advanced Settings are unique mapping exceptions that are separate from Default and Custom mapping. Contact our team to learn more.</p>
            <Table
                rowKey={NameOf<AdvancedSettingsDTO>('key')}
                className='borderless-table'
                loading={loading}
                pagination={false}
                columns={tableColumns}
                dataSource={tableData}
            />
        </div>
    );
};

export default LocationSettingsAdvancedSettings;
