import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { NameOf } from 'src/utils';

export interface SageIntacctConnectModalProps {

    // if the modal should be shown
    open: boolean;

    // function to run when the user clicks 'cancel' or clicks outside of the modal area
    onCancel?: () => void

    // function to run when the user clicks 'save'
    onSubmit: (formData: any) => void

}

interface SageIntacctConnectForm {
    senderId: string,
    senderPassword: string,
    companyId: string,
    userId: string,
    userPassword: string,
    entityId: string
}

const SageIntacctConnectModal: React.FC<SageIntacctConnectModalProps> = (props) => {

    const [form] = Form.useForm<SageIntacctConnectForm>();
    const [formDisabled, setFormDisabled] = useState(true);

    // Because the submit button is outside of the form, we have to watch the state of the fields
    // in order to know when to disable the submit button (or any input validation)
    const senderId = Form.useWatch(NameOf<SageIntacctConnectForm>('senderId'), form);
    const senderPass = Form.useWatch(NameOf<SageIntacctConnectForm>('senderPassword'), form);
    const companyId = Form.useWatch(NameOf<SageIntacctConnectForm>('companyId'), form);
    const userId = Form.useWatch(NameOf<SageIntacctConnectForm>('userId'), form);
    const userPass = Form.useWatch(NameOf<SageIntacctConnectForm>('userPassword'), form);

    const formVals = [senderId, senderPass, companyId, userId, userPass];

    useEffect(() => {
        setFormDisabled(!formVals.every(val => val && val.length > 0));
    }, formVals);

    const onReset = () => {
        form.resetFields();
    };

    const handleSubmit = () => {
        props.onSubmit(form.getFieldsValue());
        form.resetFields();
    };

    return (
        <Modal
            destroyOnClose
            open={props.open}
            width={600}
            onCancel={props.onCancel}
            footer={<>
                <Button type='default' onClick={props.onCancel}>Cancel</Button>
                <Button type='default' onClick={onReset}>Reset</Button>
                <Button type='primary' onClick={handleSubmit} disabled={formDisabled}>Save</Button>
            </>}>
            <h2>Connect to Sage Intacct</h2>
            <Form
                size={'large'}
                layout='horizontal'
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                    label={'Sender Id'}
                    name={NameOf<SageIntacctConnectForm>('senderId')}
                    rules={[{ required: true, message: 'Sender Id is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'Sender Password'}
                    name={NameOf<SageIntacctConnectForm>('senderPassword')}
                    rules={[{ required: true, message: 'Sender Password is required' }]}>
                    <Input.Password allowClear />
                </Form.Item>
                <Form.Item
                    label={'Company Id'}
                    name={NameOf<SageIntacctConnectForm>('companyId')}
                    rules={[{ required: true, message: 'Company Id is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'User Id'}
                    name={NameOf<SageIntacctConnectForm>('userId')}
                    rules={[{ required: true, message: 'User Id is required' }]}>
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={'User Password'}
                    name={NameOf<SageIntacctConnectForm>('userPassword')}
                    rules={[{ required: true, message: 'User Password is required' }]}>
                    <Input.Password allowClear />
                </Form.Item>
                <Form.Item
                    label={'Entity Id'}
                    name={NameOf<SageIntacctConnectForm>('entityId')}>
                    <Input allowClear />
                </Form.Item>
            </Form>
        </Modal>);

};

export default SageIntacctConnectModal;